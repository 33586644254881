/* Animations */
@keyframes slideInDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideInDown {
  animation: slideInDown 0.5s ease-out forwards;
}

/* Grid system */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 100vh;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -1rem;
  height: 100vh;
}

.col-lg-7 {
  width: 100%;
  padding: 0 1rem;
}

.col-lg-5 {
  width: 100%;
  padding: 0 1rem;
}

@media (min-width: 992px) {
  .col-lg-7 {
    width: 58.333333%;
  }
  .col-lg-5 {
    width: 41.666667%;
  }
  .text-lg-end {
    text-align: end;
  }
}

/* Hero styles */
.hero-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.hero-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
}

.hero-slider.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background-color: #191538;
}

.hero-style5 {
  padding: 120px 0;
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

.hero-text {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.hero-year-tag {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  padding: 1rem;
  border-radius: 0.5rem;
  width: fit-content;
}

.circle-btn {
  position: relative;
  display: inline-block;
  background: white;
  color: black;
  padding: 2rem;
  border-radius: 9999px;
  transition: all 0.3s ease;
  z-index: 2;
}

.link-effect {
  position: relative;
  display: block;
  overflow: hidden;
}

.effect-1 {
  display: block;
  transition: transform 0.3s ease;
}

.effect-1:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.circle-btn:hover .effect-1:first-child {
  transform: translateY(-100%);
}

.circle-btn:hover .effect-1:nth-child(2) {
  transform: translateY(0);
}